import Loadable from 'react-loadable';
import Loading from '../components/loading';

const MenuRouter = [
    {
        name: '首页',
        path: '/',
        icons: '#icon-kongzhitai-zhuye',
        showHead: true,
        component: Loadable({
            loader: () => import('../view/home'),
            loading: Loading,
        }),
    },
    {
        name: '关于我们',
        path: '/about',
        icons: "#icon-tishi",
        showHead: true,
        component: Loadable({
            loader: () => import('../view/about'),
            loading: Loading,
        }),
    },
    {
        name: '渠道合作',
        path: '/channel',
        icons: "#icon-23hangye5",
        showHead: true,
        component: Loadable({
            loader: () => import('../view/channel'),
            loading: Loading,
        }),
    }
]

const MenuItemRouter = [
    {
        name: '拓客线索',
        path: '/clue',
        showHead: true,
        component: Loadable({
            loader: () => import('../view/clue'),
            loading: Loading,
        }),
    },
    {
        name: 'AI机器人',
        path: '/ai-skill',
        showHead: true,
        component: Loadable({
            loader: () => import('../view/ai-skill'),
            loading: Loading,
        }),
    },
    {
        name: '触达',
        path: '/intelligent-reach',
        showHead: true,
        component: Loadable({
            loader: () => import('../view/intelligent-reach'),
            loading: Loading,
        }),
    },
    {
        name: 'CRM',
        path: '/crm',
        showHead: true,
        component: Loadable({
            loader: () => import('../view/crm'),
            loading: Loading,
        }),
    },
    {
        name: '新闻详情',
        path: '/newsDetail/:id',
        showHead: false,
        component: Loadable({
            loader: () => import('../view/news-detail'),
            loading: Loading,
        }),
    },
    {
        name: '加入我们',
        path: '/joinChannel',
        showHead: false,
        component: Loadable({
            loader: () => import('../view/join-channel'),
            loading: Loading,
        }),
    }
]

export {
    MenuRouter,
    MenuItemRouter
}
import React from 'react'
// import RenderLayout from './render-layout/index'
import { Redirect, Switch, Route, BrowserRouter } from 'react-router-dom';
import { MenuRouter, MenuItemRouter } from '../router/index'

const BaseLayout = () =>{
	const renderRouter = () =>{
		const routes:any[] = [];
		MenuRouter.forEach((authUrlConfigItem:any) => {
				routes.push(<Route key={authUrlConfigItem.path} component={authUrlConfigItem.component} path={authUrlConfigItem.path} exact />);
		})
		MenuItemRouter.forEach((authUrlConfigItem:any) => {
			routes.push(<Route key={authUrlConfigItem.path} component={authUrlConfigItem.component} path={authUrlConfigItem.path} exact />);
		})
		return routes;
	}

    return (
			<BrowserRouter>
				<Route>
					<Switch>
						{
							renderRouter()
						}
						<Redirect to="/" />
					</Switch>
				</Route>
			</BrowserRouter>
    )
}



export default BaseLayout

// const ShowTheLocationWithRouter = withRouter(BaseLayout);
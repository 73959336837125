import React from 'react';
import { Icon } from 'antd-mobile'
import styles from './index.module.css';

interface AsyncComponentLoadingProp {
  isLoading?: boolean;
  timedOut?: boolean;
  pastDelay?: boolean;
  error?: boolean;
}

function Loading(props: AsyncComponentLoadingProp) {
  const {
    isLoading,
    timedOut,
    pastDelay,
    error,
  } = props;
  const renderLoading = () => {
    if (isLoading) {
      if (timedOut) {
        return <div>服务器在忙，请刷新重试</div>;
      }

      if (pastDelay) {
        return <Icon type="loading" />;
      }
      return null;
    }

    if (error) {
      return <div>服务器在忙，请刷新重试</div>;
    }

    return null;
  };

  return (
    <div className={styles.loadingWrap}>
      {
        renderLoading()
      }
    </div>
  );
}

export default Loading;
